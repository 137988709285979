import React from "react";
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

const ContactPage = () => (
  <Layout>
    <Seo
      title="Anfahrt & Kontakt | Verhaltenstherapie Berlin Buch"
      description="Psychotherapie Berlin, Wiltbergstraße 36. Kontakt: antjemuetzel@gmx.de | 0151 5691 1373. Freie Therapieplätze auf Anfrage."
    />
    <div className="relative">
      <div className="w-full h-96">
        <StaticImage
          className="w-full h-full"
          src="../assets/images/hero-ueber-mich.jpg"
          alt=""
        />
        <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
          <div className="w-full text-center text-white">
            <h1 className="lg:text-4xl">Kontakt</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-8 mb-2 md:my-12 boxed max-w-prose generic-page">
      <p>
        Die Praxis befindet sich im grünen Berliner Norden in direkter Nähe zum
        S-Bahnhof Buch und ist gut mit dem ÖPNV erreichbar (S2, Bus 150, Bus
        158). Es stehen kostenfreie Parkplätze zur Verfügung. Der Eingang
        befindet sich auf der linken Seite des Hauses. Der Praxisraum befindet
        sich im 2. OG (auf dieser Etage arbeiten auch eine podologische Praxis
        sowie eine Diplom-Biologin mit Heilpraxis), Fahrstuhl und barrierefreies
        WC sind vorhanden.
      </p>
    </div>
    <div className="grid max-w-6xl gap-1 mx-auto md:grid-cols-2 md:h-screen md:gap-48 ">
      <div className="flex items-center md:order-2">
        <div className="w-full p-4 space-y-4 sm:max-w-md sm:mx-auto">
          <div>
            <strong className="uppercase">Adresse:</strong>
            <br />
            <address className="not-italic">
              Antje Mützel <br />
              c/o Podologie Zentrum Berlin-Buch <br />
              Wiltbergstraße&nbsp;36 | 2.&nbsp;OG <br />
              13125 Berlin-Buch
            </address>
          </div>
          <div className="col-span-1">
            <strong className="uppercase">Email:</strong>
            <br />
            <a
              className="text-left text-gray-700 hover:underline "
              href="mailto:antjemuetzel@gmx.de"
            >
              antjemuetzel@gmx.de
            </a>
          </div>
          <div className="lg:col-span-2">
            <strong className="uppercase">Telefon:</strong>
            <br />
            <a
              className="text-gray-700 hover:underline"
              href="tel:+4915156911373"
            >
              0151 – 5691 1373
            </a>
            <p className="mt-2">
              Unter dieser Telefonnummer erreichen Sie direkt meine Mailbox,
              sodass Sie jederzeit anrufen können, ohne sich über eine mögliche
              Störung Gedanken machen zu müssen. Bitte hinterlassen Sie
              unbedingt Ihre Telefonnummer sowie Ihren Namen. Ich rufe in der
              Regel innerhalb von zwei Werktagen zurück.
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center p-4 bg-red-100">
        <StaticImage
          className="max-w-md mx-auto transform md:translate-x-32"
          src="../assets/images/antje-muetzel-psychotherapie-berlin.jpg"
          alt=""
        />
      </div>
    </div>
    {/* <div className="my-12 boxed max-w-prose generic-page">
      <h2 className="h3">Hinweis:</h2>
      <p>
        COVID 19: Sitzungen vor Ort sowie per Video-Konferenz über zertifizierte
        Videoanbieter mit Ende-zu-Ende-Verschlüsselung möglich.
      </p>
    </div> */}
  </Layout>
);

export default ContactPage;
